import React, { useEffect, useState } from 'react';
import AdModal from './components/AdModal.jsx';
import TimelineModal from './components/TimelineModal.jsx';
import { RiEmotionSadLine } from "react-icons/ri";
import { RoughNotation } from "react-rough-notation";

const navigation = [
  { name: 'Home', href: '#home' },
  { name: 'About Us', href: '#about' },
  { name: 'Our Product', href: '#product' },
  { name: 'Meet the Team', href: '#team' },
]

const TimelineItem = ({ date, header, title, description, modal }) => (
  <div className="relative pl-12 py-6 group transition hover:bg-white/5 rounded-lg p-1 mb-2 select-none cursor-pointer" onClick={() => modal ? modal.open() : null}>
      <div className="font-caveat font-medium text-2xl text-indigo-500 mb-1 sm:mb-0 timelineFont">
        {header}
      </div>
      <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-[#606060] sm:before:ml-[1.75rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-green-400 after:border-4 after:box-content after:border-[#606060] after:rounded-full sm:after:ml-[1.75rem] after:-translate-x-1/2 after:translate-y-1.5">
          <div className="text-2xl font-bold text-white">
            {title}
          </div>
      </div>
      <div className="text-slate-400 font-medium text-lg">
        {description}
      </div>
  </div>
)

const timeline = [
  {
    date: 'September',
    header: 'The Beginning',
    title: 'Potify is born',
    description: 'Potify was created as our initial Young Enterprise project. We sold hanging plant pots as a way to bring nature into peoples home.',
    img: '../assets/potify.png'
  },
  {
    date: 'November',
    header: 'The Downfall',
    title: 'End of Potify',
    description: 'Unfortunately, due to a lack of market research and a poor product, Potify was not a success. We learned a lot from this experience and used it to improve our next project.',
    img: '../assets/potify-fail.png'
  },
  {
    date: 'December',
    header: 'Rebuilding',
    title: 'Rebuilding our team',
    description: 'Due to this experience, we lost most of our team - going from 15 members to just 3. The remaining members decided to rebuild and start a new project.',
    img: '../assets/rebuild_team.png'
  },
  {
    date: 'January',
    header: 'The Rebirth',
    title: 'Luce del vino is born',
    description: 'Luce Del Vino is our current project. We create sustainable and eco-friendly lights from repurposed wine bottles.',
    img: '../assets/IMG_0031.png'
  }
]

const people = [
  {
    name: 'Anna',
    role: 'Managing Director, Sustainability Director',
  },
  {
    name: 'Ben',
    role: 'Marketing Director',
  },
  {
    name: 'Ollie',
    role: 'Secretary, Finance Director',
  },
  {
    name: 'Mya',
    role: 'Sales Director',
  },
  {
    name: 'Emily',
    role: 'Creativity Director',
  },
  {
    name: 'Meg',
    role: 'Technology Director',
  },
  {
    name: 'Oscar',
    role: 'Operations Director',
  },
  {
    name: 'Fletcher',
    role: 'HR Director',
  }
]

function App() {
  const [adModalOpen, setAdModalOpen] = useState(false)

  const [timelineModal, setTimelineModal] = useState({ open: false, item: null })

  const [showHeaderBg, setShowHeaderBg] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 725) {
        setShowHeaderBg(true)
      } else {
        setShowHeaderBg(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      
      <img src="../assets/bg.mp4" alt="" className="hidden" />
      <div className="select-none">
        <div className="bg-gray-900">

          <header className={`inset-x-0 top-0 z-50 fixed transition duration-300 ${showHeaderBg ? 'bg-[#151515]/10 backdrop-blur-md' : 'bg-transparent'}`}>
            <nav className="flex items-center justify-center p-6 lg:px-8" aria-label="Global">
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white focus:ring-none focus:outline-none hover:text-gray-200 transition">
                    {item.name}
                  </a>
                ))}
              </div>
            </nav>
          </header>

          <div className="relative isolate overflow-hidden pt-14 h-screen flex items-center justify-center" id="home">
            <video src="../assets/bg.mp4" autoPlay loop muted className="absolute inset-0 -z-10 h-full w-full object-cover filter" />
            <div
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div className="relative isolate -z-10 -mt-12">
              <div className="overflow-hidden w-screen">
                <div className="mx-auto max-w-8xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32 mx-8">
                  <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                    <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                      <h1 className="text-8xl font-bold tracking-tight text-white logoFont">
                        Luce del vino
                      </h1>
                      <p className="relative mt-6 text-lg leading-8 font-medium text-gray-200">
                        Premium wine lights from repurposed and sustainable bottles sourced from the Cheshire region.
                      </p>
                      <button
                        type="button"
                        className="rounded-md bg-white/10 px-8 py-3 text-md font-semibold text-white shadow-sm hover:bg-white/20 mt-4 transition focus:ring-none focus:outline-none"
                        onClick={() => setAdModalOpen(true)}
                      >
                        Watch Advert
                      </button>
                      <p className="mt-2 text-sm leading-8 text-gray-400">
                        Background is Bickerton Hills, Cheshire
                      </p>
                    </div>
                    <div className="mt-14 flex justify-end gap-8 sm:-mt-32 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0 -ml-12">
                      <div className="ml-auto w-48 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                        <div className="relative">
                          <img
                            src="../assets/IMG_3579.JPG"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </div>
                      <div className="mr-auto w-48 flex-none space-y-8 sm:mr-0 sm:pt-28">
                        <div className="relative">
                          <img
                            src="../assets/bottleImages/IMG_0024.JPG"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative">
                          <img
                            src="../assets/ldv_freya.png"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </div>
                      <div className="w-48 flex-none space-y-8 pt-32 mt-8 sm:pt-0">
                        <div className="relative">
                          <img
                            src="../assets/IMG_3613.png"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative">
                          <img
                            src="../assets/bottleImages/IMG_0018.JPG"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#151515]" id="about">
          <div className="pt-24 pb-4">
            <h2 className="text-6xl font-bold text-white text-center">
              About Us
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-400 text-center">
              Click on an event to learn more about our journey!
            </p>

            <div className="mt-12 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
              {timeline.map((item, index) => (
                <TimelineItem key={index} {...item} modal={{ open: () => setTimelineModal({ open: true, item }), item }} />
              ))}
            </div>
          </div>    
        </div>

        <div className="bg-[#151515] text-center" id="product">
          <div className="pt-24 pb-4">
            <h2 className="text-6xl font-bold text-white text-center">
              Our Product
            </h2>

            <div className="mt-12 max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col space-y-24 justify-center">
                  <div className="flex flex-col space-y-2 items-center max-w-sm m-auto">
                    <h3 className="text-4xl font-semibold text-white z-10">
                      <RoughNotation type="highlight" show animationDuration={2000} color="#cf6390" strokeWidth={3} padding={[10,10,10,10]}>
                        Repurposed Bottles
                      </RoughNotation>
                    </h3>
                    <p className="mt-1 text-gray-400">
                      We use repurposed bottles from Cheshire pubs
                    </p>
                  </div>
                  <div className="flex flex-col space-y-2 items-center max-w-sm m-auto">
                    <h3 className="text-4xl font-semibold text-white z-10">
                      <RoughNotation type="highlight" show animationDuration={2000} color="#00ad8e" strokeWidth={3} padding={[10,10,10,10]}>
                        Product Variations
                      </RoughNotation>
                    </h3>
                    <p className="mt-1 text-gray-400">
                      We offer the ability to change the cork light to multicoloured and custom engraving, offering choice to customers.
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <img
                    src="../assets/IMG_3613.png"
                    alt=""
                    className="aspect-[1/4] w-full max-h-[65vh] rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="flex flex-col space-y-24 justify-center">
                  <div className="flex flex-col space-y-2 items-center max-w-sm m-auto">
                    <h3 className="text-4xl font-semibold text-white z-10">
                      <RoughNotation type="highlight" show animationDuration={2000} color="#25cc12" strokeWidth={3} padding={[10,10,10,10]}>
                        Engraved
                      </RoughNotation>
                    </h3>
                    <p className="mt-1 text-gray-400">
                      Our bottles are engraved with our logo to help promote our brand.
                    </p>
                  </div>
                  <div className="flex flex-col space-y-2 items-center max-w-sm m-auto">
                    <h3 className="text-4xl font-semibold text-white z-10">
                      <RoughNotation type="highlight" show animationDuration={2000} color="#d17819" strokeWidth={3} padding={[10,10,10,10]}>
                        Unit Cost
                      </RoughNotation>
                    </h3>
                    <p className="mt-1 text-gray-400">
                      We took total unit cost into consideration when creating and marketing our product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </div>

        <div className="py-12" id="team">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-12">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-6xl font-bold tracking-tight text-white">Meet the team</h2>
              <p className="mt-6 text-lg leading-8 text-gray-400">
                We're the Young Enterprise from Bishop Heber High School, Malpas.
              </p>
            </div>
            <ul
              className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-4 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
            >
              {people.map((person) => (
              // center the image
                <li key={person.name} className="flex flex-col text-center items-center space-x-4 w-full mx-auto">
                  <img
                    src={`../assets/team/${person.name.toLowerCase()}.jpeg`}
                    alt=""
                    // center the image
                    className="w-32 h-32 rounded-full bg-gray-900/5 object-cover shadow-lg mx-auto"
                  />
                  <div>
                    <h3 className="text-2xl font-semibold text-white mt-2">{person.name}</h3>
                    <p className="mt-1 text-gray-400">{person.role}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center p-48 bg-[#151515] overflow-hidden relative">
          <div style={{ zIndex: 1 }} className="absolute -rotate-12">
            {Array.from({ length: 10 }).map((_, index) => (
              <div className="flex justify-center items-center">
                {Array.from({ length: 100 }).map((_, index) => (
                  <RiEmotionSadLine className="text-9xl text-yellow-500/10 blur-xs" />
                ))}
              </div>
            ))}
          </div>
          <div style={{ zIndex: 2 }} className="relative bg-transparent p-12">
            <p className="text-xl text-white/75 text-center mb-2">
              In regards to Potify:
            </p>
            <h1 className="text-4xl font-bold text-white">
              "Your product will never work"
            </h1>
            <div className="flex flex-col justify-center items-center">
              <p className="mt-6 text-lg leading-8 text-white/75 text-center">
                - Jonathan Garnham
              </p>
              <p className="-mt-2 text-sm leading-8 text-white/75 text-center">
                Director of Sixth Form
              </p>
            </div>
          </div>
        </div>

        <AdModal
          open={adModalOpen}
          setOpen={setAdModalOpen}
        />
        <TimelineModal
          open={timelineModal.open}
          setOpen={() => setTimelineModal({ open: false, item: timelineModal.item })}
          item={timelineModal.item}
        />
      </div>
    </>
  );
}

export default App;