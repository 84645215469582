import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Example({ open, setOpen, item }) {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative" onClose={setOpen} style={{ zIndex: 1000 }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#202020] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="flex items-center justify-center">
                                        <img
                                            src={item?.img}
                                            alt={item?.title}
                                            className="max-h-72 w-fit object-cover rounded-lg"
                                        />
                                    </div>
                                    <div className="text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-3xl font-bold leading-6 text-white">
                                            {item?.title}
                                        </Dialog.Title>

                                        <div className="mt-2">
                                            <p className="text-sm text-gray-300">
                                                {item?.date}
                                            </p>
                                            <p className="text-md mt-2 text-gray-300">
                                                {item?.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white/10 px-8 py-3 w-full text-md font-semibold text-white shadow-sm hover:bg-white/20 mt-4 transition focus:ring-none focus:outline-none"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}